.order-wrapper-block{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 3vw;
    box-sizing: border-box;
}
.order-logo-block{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 22%;
    height: 100%;
    background-color: #686868;
    color: #010200;
    font-size: 45px;
}
.order-title{
    flex-basis: content;
    width: 100%;
    text-transform: uppercase;
    font-size: 2.8vw;
    text-align: center;
}
.order-lineitem-wrapper{
    padding-right: 0 !important;
    padding-left: 0 !important;
}
.order-lineitem-wrapper:last-of-type{
    border-bottom: none !important;
}
.order-lineitem-wrapper.one:last-of-type{
    border-bottom: 1px solid rgba(0, 0, 0, 0.12) !important;
}
.order-title-big.head{
    font-weight: bold !important;
}
.order-list-item{
    justify-content: space-between !important;
    width: 100% !important;
    padding: 0 !important;
    font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
    font-weight: bold;
    line-height: 1.4em;
}

.order-title-small{
    font-size: 1.3vw !important;
}
.order-title-count{
    margin-left: auto !important;
    margin-right: 15vw !important;
}
.order-title-big{
    font-size: 1.5vw !important;
    font-weight: bold;
}
.order-title-big, .order-title-small{
    min-width: 9vw;
}
.order-title-count{
    min-width: unset !important;
}
.wrap-amount{
    display: flex;
    justify-content: space-between;
    width: 100%;
    /*max-width: 28%;*/
    /*max-width: 33vw;*/
    max-width: 20vw;
    text-align: right;
}
.logo__wrapper{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}
.logo{
    width: 100%;
    max-width: 14vw;
}
.logo__title{
    color: white;
    text-align: left;
}

.order-none{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 25px;
}
.order-bold-text-subtotal{
    font-weight: bold !important;
    /*font-size: 1.7vw !important;*/
}
.order-bold-text-total{
    font-weight: bold !important;
    font-size: 1.9vw !important;
}

/* width */
.scrollbar::-webkit-scrollbar {
    width: 6px;
    border-radius: 10px;
}

/* Track */
.scrollbar::-webkit-scrollbar-track {
    background: transparent;
    position: relative;
    left: 10px;
}

/* Handle */
.scrollbar::-webkit-scrollbar-thumb {
    background: #888;
}

/* Handle on hover */
.scrollbar::-webkit-scrollbar-thumb:hover {
    background: #555;
}
@media screen and (max-width: 1024px){
    .order-title-big{
        font-size: 2.5vw !important;
    }
    .order-bold-text-total{
        font-size: 2.9vw !important;
    }
    .order-bold-text-subtotal{
        /*font-size: 2.7vw !important;*/
    }
    .order-title-small{
        font-size: 2.3vw !important;
    }
    .order-title-big, .order-title-small{
        min-width: 16vw;
    }
    .order-title-count{
        min-width: unset !important;
        margin-right: 5vw !important;
    }
    .wrap-amount{
        min-width: 35vw;
        text-align: right;
    }
    .order-title{
        font-size: 3.5vw;
    }
}
@media screen and (max-width: 991px){
    .order-wrapper-block{
        width: 100%;
    }
    .order-logo-block{
        display: none;
    }
    .order-title-big{
        font-size: 2.9vw !important;
    }
    .order-bold-text-total{
        font-size: 3.5vw !important;
    }
    .order-title-small{
        font-size: 2.7vw !important;
    }
    .order-title{
        font-size: 5vw;
    }
}
